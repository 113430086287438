<template>
  <v-navigation-drawer
    :value="isAddNewPayoutSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 550 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-payout-sidebar-active', val)"
  >
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Do you continue?
        </v-card-title>
        <v-card-text>A new payout will be created which will remain on Pending status until its processing.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="loading"
            @click="isDialogVisible = false"
          >
            No
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onAgree"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span
          class="font-weight-semibold text-base text--primary"
        >Create New Payout</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-payout-sidebar-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-autocomplete
            v-model="payoutData.shopId"
            :rules="[validators.required]"
            placeholder="Shop"
            :loading="loading"
            :items="shopFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details="auto"
            class="mb-6"
            @change="onShopChanged"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Shop
            </template>
          </v-autocomplete>

          <v-select
            v-model="payoutData.country"
            :loading="loading"
            :rules="[validators.required]"
            placeholder="Country"
            :items="countryFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details="auto"
            class="mb-6"
            @change="onCountryChanged"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Country
            </template>
          </v-select>

          <v-select
            v-model="payoutData.payoutMethodId"
            :loading="loading"
            :rules="[validators.required]"
            placeholder="Method"
            :items="payoutMethodFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details="auto"
            class="mb-6"
            @change="onPayoutMethodChangeHandler"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Method
            </template>
          </v-select>

          <v-select
            v-model="payoutData.currencyCode"
            :loading="loading"
            :rules="[validators.required]"
            placeholder="Currency"
            :items="currencyTypeFilterItems"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Currency
            </template>
          </v-select>

          <v-text-field
            v-model="payoutData.amount"
            outlined
            dense
            :rules="[validators.required, validators.decimalValidator]"
            type="number"
            placeholder="Amount"
            hide-details="auto"
            class="mb-6"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Amount
            </template>
          </v-text-field>

          <v-text-field
            v-model="payoutData.notificationUrl"
            outlined
            dense
            label="Notification Url"
            placeholder="Notification Url"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="payoutData.referenceCode"
            outlined
            dense
            label="Merchant Reference"
            placeholder="Merchant Reference"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="payoutData.description"
            :rules="[validators.required]"
            outlined
            dense
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          >
            <template #label>
              <span class="red--text"><strong>* </strong></span>Description
            </template>
          </v-text-field>

          <v-card
            class="mx-auto px-2 mb-5"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  BENEFICIARY DETAILS
                </div>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.firstName"
                    outlined
                    dense
                    :rules="[validators.required]"
                    placeholder="First Name"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>First Name
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.lastName"
                    :rules="[validators.required]"
                    outlined
                    dense
                    placeholder="Last Name"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Last Name
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  v-show="isDocumentTypeRequired"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-if="isDocumentTypeRequired"
                    v-model="payoutData.documentType"
                    :loading="loading"
                    :rules="[validators.required]"
                    placeholder="Document Type"
                    :items="documentTypeFilterItems"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Document Type
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.documentNumber"
                    :rules="[validators.required]"
                    outlined
                    dense
                    placeholder="Document Number"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Document Number
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.email"
                    :rules="[validators.required, validators.emailValidator]"
                    outlined
                    dense
                    type="email"
                    placeholder="E-mail"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>E-mail
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.phone"
                    :rules="[validators.required]"
                    outlined
                    dense
                    type="number"
                    placeholder="Phone"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Phone
                    </template>
                  </v-text-field>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-card
            class="mx-auto px-2 mb-5"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  BANK DETAILS
                </div>

                <v-col
                  v-show="isBankTypeRequired"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-if="isBankTypeRequired"
                    v-model="payoutData.bankCode"
                    :loading="loading"
                    :rules="[validators.required]"
                    placeholder="Bank"
                    :items="bankTypeFilterItems"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Bank
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  v-show="isAccountTypeRequired"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-if="isAccountTypeRequired"
                    v-model="payoutData.accountType"
                    :loading="loading"
                    :items="accountTypeFilterItems"
                    :rules="[validators.required]"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Account Type
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="payoutData.account"
                    outlined
                    dense
                    :rules="[validators.required]"
                    placeholder="Account Number"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Account Number
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  v-show="isAccountDigitRequired"
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-if="isAccountDigitRequired"
                    v-model="payoutData.accountDigit"
                    outlined
                    dense
                    label="Account Digit"
                    placeholder="Account Digit"
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  v-show="isBranchRequired"
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-if="isBranchRequired"
                    v-model="payoutData.branch"
                    outlined
                    dense
                    :rules="[validators.required]"
                    placeholder="Branch"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Branch
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  v-show="isRegionTypeRequired"
                  cols="12"
                  sm="12"
                >
                <v-select
                    v-if="isRegionTypeRequired"
                    v-model="payoutData.region"
                    :loading="loading"
                    :items="regionTypeFilterItems"
                    :rules="[validators.required]"
                    item-text="text"
                    item-value="value"
                    outlined
                    dense
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Region
                    </template>
                  </v-select>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :disabled="!valid"
          >
            Submit
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetPayoutData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      :timeout="-1"
      top
      centered
      color="red accent-2"
      tile
    >
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator, decimalValidator } from '@core/utils/validation'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewPayoutSidebarActive',
    event: 'update:is-add-new-payout-sidebar-active',
  },
  props: {
    isAddNewPayoutSidebarActive: {
      type: Boolean,
      required: true,
    },
    shopFilterItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const blankPayoutData = {
      amount: '',
      shopId: '',
      payoutMethodId: '',
      currencyCode: '',
      notificationUrl: '',
      referenceCode: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      documentType: '',
      documentNumber: '',
      country: '',
      accountType: '',
      accountDigit: '',
      account: '',
      bankCode: '',
      region: '',
      description: '',
      branch: '',
    }

    const payoutData = ref(JSON.parse(JSON.stringify(blankPayoutData)))

    const countryFilterItems = ref([])
    const payoutMethodFilterItems = ref([])
    const bankTypeFilterItems = ref([])
    const accountTypeFilterItems = ref([])
    const documentTypeFilterItems = ref([])
    const regionTypeFilterItems = ref([])
    const currencyTypeFilterItems = ref([])

    const isBankTypeRequired = ref(false)
    const isAccountTypeRequired = ref(false)
    const isDocumentTypeRequired = ref(false)
    const isAccountDigitRequired = ref(false)
    const isRegionTypeRequired = ref(false)
    const isBranchRequired = ref(false)

    const loading = ref(false)
    const isDialogVisible = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const showSnackbarMessage = data => {
      let msg = data.message
      if (data.type === 'error') {
        msg = '<p class="red--text">ERROR:</p>'
        data.message.forEach(x => {
          msg += `<li>${x.message}</li>`
        })
      }
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = msg
    }

    const resetPayoutData = () => {
      payoutData.value = JSON.parse(JSON.stringify(blankPayoutData))
      resetForm()
      emit('update:is-add-new-payout-sidebar-active', false)
    }

    const onShopChanged = () => {
      payoutMethodFilterItems.value = []
      bankTypeFilterItems.value = []
      accountTypeFilterItems.value = []
      documentTypeFilterItems.value = []
      currencyTypeFilterItems.value = []

      loading.value = true

      store
        .dispatch('processing-payouts/fetchAvailableCountriesConfig', {
          shopId: payoutData.value.shopId,
        })
        .then(response => {
          countryFilterItems.value = response.data
        }).finally(() => {
          loading.value = false
        })
    }

    const onCountryChanged = () => {
      loading.value = true

      store
        .dispatch('processing-payouts/fetchAvailablePayoutMethodsConfig', {
          countryCode: payoutData.value.country,
          shopId: payoutData.value.shopId,
        })
        .then(response => {
          payoutMethodFilterItems.value = response.data
        }).finally(() => {
          loading.value = false
        })
    }

    const onPayoutMethodChangeHandler = () => {
      loading.value = true

      store
        .dispatch('processing-payouts/fetchPayoutMethodDetailsConfig', {
          id: payoutData.value.payoutMethodId,
        })
        .then(response => {
          const {
            bankTypes,
            regionTypes,
            accountTypes,
            documentTypes,
            currencyTypes,
          } = response.data

          isBankTypeRequired.value = response.data.isBankTypeRequired
          isAccountTypeRequired.value = response.data.isAccountTypeRequired
          isDocumentTypeRequired.value = response.data.isDocumentTypeRequired
          isAccountDigitRequired.value = response.data.isAccountDigitRequired
          isRegionTypeRequired.value = response.data.isRegionTypeRequired
          isBranchRequired.value = response.data.isBranchRequired

          bankTypeFilterItems.value = bankTypes
          accountTypeFilterItems.value = accountTypes
          documentTypeFilterItems.value = documentTypes
          regionTypeFilterItems.value = regionTypes
          currencyTypeFilterItems.value = currencyTypes
        }).finally(() => {
          loading.value = false
        })
    }

    const onSubmit = () => {
      if (valid.value) {
        isDialogVisible.value = true
      } else {
        validate()
      }
    }
    const onAgree = () => {
      loading.value = true

      store.dispatch('processing-payouts/addPayout', payoutData.value).then(response => {
        emit('refetch-data')
        emit('update:is-add-new-payout-sidebar-active', false)
        emit('payout-saved', response.data)
        resetPayoutData()
      }).catch(e => {
        showSnackbarMessage({ type: 'error', message: e.response.data.errors })

        return e
      }).finally(() => {
        loading.value = false
        isDialogVisible.value = false
      })
    }

    return {
      form,
      payoutData,
      snackbar,
      isDialogVisible,
      valid,
      countryFilterItems,
      payoutMethodFilterItems,
      bankTypeFilterItems,
      accountTypeFilterItems,
      documentTypeFilterItems,
      currencyTypeFilterItems,
      regionTypeFilterItems,
      isBankTypeRequired,
      isAccountTypeRequired,
      isDocumentTypeRequired,
      isAccountDigitRequired,
      isRegionTypeRequired,
      isBranchRequired,
      loading,
      onAgree,
      onSubmit,
      showSnackbarMessage,
      resetPayoutData,
      onCountryChanged,
      onShopChanged,
      onPayoutMethodChangeHandler,
      validate,

      // validation
      validators: { required, emailValidator, decimalValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
